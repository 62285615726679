import React from "react";
import {documentReady} from "../../utils/global-functions";
import {Link} from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll";

const RpaNav = ({pageContext}) => {
    const baseUrl = '/' + pageContext.locale.path.default + '/rpa';
    const index = baseUrl;
    const contact = pageContext.locale.path.default === 'es' ? 'Contactar' : 'Contact';

    let navbar;
    let sticky;

    documentReady(() => {
        navbar = document.getElementById("navbar");
        sticky = navbar.offsetTop > 83 ? navbar.offsetTop : 83;
        window.onscroll = function () {
            onScroll()
        };

        document.querySelectorAll('.brand-link').forEach(element => {
            element.addEventListener('click', e => {
                if (element.getAttribute('href') === window.location.pathname) {
                    e.preventDefault();
                    scrollTo('#product-top');
                }
            });
        });
    })

    function onScroll() {
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky")
        } else {
            navbar.classList.remove("sticky");
        }
    }

    function openNav() {
        document.getElementById("myNav").style.width = "100%";
    }

    function closeNav() {
        document.getElementById("myNav").style.width = "0%";
    }

    return (
        <>
            <div id="myNav" className="overlay-side">
                <span className="closebtn" onClick={closeNav}>&times;</span>
                <div className="overlay-side-content">
                    <a href="#" data-toggle="modal" data-target="#modal-contact-sales">
                        <span className="text-tecalis spacing" style={{fontWeight: '400'}}>{contact}</span>
                    </a>
                </div>
            </div>

            <div id="navbar">
                <nav className="navbar navbar-expand-lg navbar-light navbar-togglable bg-white border-bottom border-top">
                    <div className="container-fluid">
                        <div className="container">
                            <Link to={index} className="navbar-brand brand-link">
                                <img src={require("../../img/logo-dxbot-portal.png").default} className="navbar-brand-img" alt="Tecalis"/>
                            </Link>

                            <ul className="navbar-nav mr-auto ml-10 menu nav-desktop"/>

                            <ul className="navbar-nav ml-auto ml-10 nav-desktop">
                                <a href="#" className="btn-primary-outline mr-2" data-toggle="modal" data-target="#modal-contact-sales">{contact}</a>
                            </ul>

                            <span className="ml-auto nav-mobile" onClick={openNav} style={{cursor: 'pointer'}}> &#9776; Menú</span>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default RpaNav
