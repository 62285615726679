import React from "react"
import LayoutThird from "../../components/common/layout-third";
import SEO from "../../components/common/seo";
import RpaNav from "../../components/common/rpa-nav";
import ModalContactSales from "../../components/modals/modal-contact-sales";
import Partners from "../../components/common/partners";
import Interested from "../../components/rpa/interested";
import ContactBox from "../../components/common/contact-box";
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";
import {customElements} from "../../utils/global-functions";
import rehypeRaw from "rehype-raw";

const IndexPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiDxbotHome.edges[0].node;

    return (
        <LayoutThird pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang} title={page.seo.title} image={page.seo.image.localFile.publicURL} description={page.seo.meta_description}/>

            <RpaNav pageContext={pageContext}/>

            <div id="product-top" className="content">
                <header className="masthead" style={{backgroundImage: 'url(' + require('../../css/bg-prev.png').default + ')'}}>
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-xl-5 text-left order-xl-12" data-aos="fade-left">
                                <h1 className="spacing mb-4 display-4">{page.title}</h1>
                                <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} components={customElements('spacing mb-4')}/>
                                <p>
                                    <a href="#more-info" className="btn btn-primary spacing btn-sm mr-4 spacing smooth-scroll">
                                        {lang === 'es' ? 'Conocer más' : 'Know more'}
                                    </a>
                                    <a href="#" data-toggle="modal" data-target="#modal-contact-sales" className="ventas spacing block-btn ml-1">
                                        {lang === 'es' ? 'Contactar con ventas' : 'Contact sales'}
                                    </a>
                                </p>
                            </div>

                            <div className="col-xl-7 text-left order-xl-1" data-aos="fade-right">
                                <img src={require('../../img/products/header-dxbot.svg').default} className="header-presentation" alt="..."/>
                            </div>
                        </div>
                    </div>
                </header>
            </div>

            <section id="more-info" className="py-11 functions-sig" style={{position: 'relative', zIndex: '90'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <a href="#more-info" className="btn btn-red btn-rounded-circle shadow smooth-scroll" style={{marginTop: '-220px'}}>
                                <i className="fe fe-arrow-down"/>
                            </a>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-xl-12" data-aos="fade-down" data-aos-delay="0">
                            <hr className="black-line"/>

                            <h3>
                                <ReactMarkdown children={page.article} rehypePlugins={[rehypeRaw]}/>
                            </h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mt-6 pt-8 pt-md-11 bg-white">
                <div className="position-relative mb-11">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-12" data-aos="fade-down" data-aos-delay="200">
                                <ReactMarkdown children={page.development_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 spacing')}/>
                            </div>
                        </div>

                        <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="100">
                            <div className="col-12 col-md-12 col-lg-12 text-center">
                                <div className="position-relative pt-7 pb-7 pl-7 mb-7 mb-md-9 mt-9 rounded-xl overflow-hidden">
                                    <div className="position-absolute top-0 right-0 bottom-0 left-0 bg-gradient-multicolor1"/>

                                    <img className="position-relative img-fluid rounded-left shadow-lg land-img" src={require('../../img/products/dxbot1.jpg').default} alt="..."/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-8 pt-md-9 bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <br/><br/>

                            <h2 className="display-4 font-weight-bold spacing">
                                {page.advantage_title}
                            </h2>

                            <hr className="hr-sm my-6 my-md-8 border-gray-300"/>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <div className="card rounded-lg shadow-lg mt-2 mb-6 mb-md-0" style={{zIndex: '1'}} data-aos="fade-up">
                                <div className="card-body py-6 py-md-8">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-xl-10">
                                            <div align="center">
                                                <img src={require('../../img/products/dx-01.svg').default} alt="..."/>
                                            </div>

                                            <ReactMarkdown children={page.advantage_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg mt-6 mb-6 mb-md-0 spacing text-left')}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="position-relative mt-n15">
                <div className="shape shape-bottom shape-fluid-x svg-shim text-dark">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="#f4364c"/>
                    </svg>
                </div>
            </div>

            <section className="pt-15 bg-tecalis pb-10">
                <div className="container pt-8 pt-md-11">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <div className="row">
                                <div align="left" className="col-12 col-md-6">
                                    {page.advantage_items.slice(0, 3).map((item, i) => {
                                        return <div className="d-flex" key={i}>
                                            <div className="badge badge-lg badge-rounded-circle badge-white">
                                                <span>{item.order}</span>
                                            </div>

                                            <div className="ml-5">
                                                <p className="font-size-md mb-6 mb-md-6 text-white spacing">
                                                    {item.title}
                                                </p>
                                            </div>
                                        </div>
                                    })}
                                </div>

                                <div align="left" className="col-12 col-md-6">
                                    {page.advantage_items.slice(3, 6).map((item, i) => {
                                        return <div className="d-flex" key={i}>
                                            <div className="badge badge-lg badge-rounded-circle badge-white">
                                                <span>{item.order}</span>
                                            </div>

                                            <div className="ml-5">
                                                <p className="font-size-md mb-6 mb-md-6 text-white spacing">
                                                    {item.title}
                                                </p>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-8 py-md-11 bg-white">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <h2 className="display-4 font-weight-bold spacing">
                                {page.process_title}
                            </h2>

                            <hr className="hr-sm my-6 my-md-8 border-gray-300"/>
                        </div>
                    </div>


                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-12 col-lg-12 text-center" data-aos="fade-down">
                                <ReactMarkdown children={page.process_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-gray-700 mb-6 mb-md-6 spacing text-left')}/>

                                <br/><br/>
                            </div>
                        </div>

                        <div className="row">
                            {page.process_items.map((item, i) => {
                                return <div className="col-12 col-md-4 text-center" data-aos="fade-up" key={i}>
                                    <div className="icons text-primary mb-2">
                                        <img src={item.icon.localFile.publicURL} alt={item.title}/>
                                    </div>

                                    <br/>

                                    <h2 className="font-weight-bold spacing">
                                        {item.title}
                                    </h2>

                                    <br/>

                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing text-left')}/>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-8 py-md-11 bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <h2 className="display-4 font-weight-bold spacing">
                                {page.profits_title}
                            </h2>

                            <hr className="hr-sm my-6 my-md-8 border-gray-300"/>

                            <p className="font-size-lg text-muted mb-7 mb-md-9"/>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-10 col-offset-1" data-aos="fade-up">
                            <div className="row mt-3">
                                <div className="col-xl-6" data-aos="fade-up">
                                    <div className="card shadow-light-lg mb-6 mb-md-2 mt-6 lift lift-lg">
                                        <div className="card-body position-relative">
                                            <div className="position-relative text-right mt-n8 mr-n4 mb-3">
                                                <span className="badge badge-pill badge-tecalis">
                                                    <span className="h6 text-uppercase">Robotic Process Automation</span>
                                                </span>
                                            </div>

                                            <br/>

                                            <div align="center" data-aos="fade-right" className="mt-2 mb-2">
                                                <img src={require('../../img/products/dxbot.png').default} alt="..." width="450" className="img-fluid"/>
                                            </div>

                                            <h2 align="center" className="font-weight-bold spacing mb-5">
                                                Tecalis Dxbot
                                            </h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6" data-aos="fade-up">
                                    <div className="card accordion" id="featuresAccordion">
                                        <div className="card-body">
                                            <div className="list-group list-group-flush">
                                                <div className="list-group">
                                                    <div className="list-group">
                                                        {page.profits_items.map((item, i) => {
                                                            return <div className="list-group-item" key={i}>
                                                                <a className="d-flex align-items-center text-reset text-decoration-none" data-toggle="collapse"
                                                                   href={`#features-${i}`}
                                                                   role="button" aria-expanded={i === 0} aria-controls={`features-${i}`}>
                                                                    <div className="mr-auto">
                                                                        <span className="mr-4 font-weight-bold text-tecalis spacing">{item.title}</span>
                                                                    </div>

                                                                    <span className="collapse-chevron text-muted ml-4">
                                                                        <i className="fe fe-lg fe-chevron-down"/>
                                                                    </span>
                                                                </a>

                                                                <div className={i === 0 ? "collapse show" : "collapse"} id={`features-${i}`} data-parent="#featuresAccordion">
                                                                    <div className="py-2 py-mb-6 spacing">
                                                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </section>

            <Partners pageContext={pageContext}/>

            <Interested pageContext={pageContext}/>

            <ContactBox pageContext={pageContext} product="Dxbot"/>

            <ModalContactSales pageContext={pageContext} product="Dxbot"/>
        </LayoutThird>
    )
}

export default IndexPage

export const indexPageQuery = graphql
    `query($lang: String) {
        allStrapiDxbotHome ( filter: { locale: { eq: $lang } } )
        {
            edges {
                node {
                    id
                    seo {
                        title
                        meta_description
                        image {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    title
                    description
                    article
                    development_description
                    advantage_title
                    advantage_description
                    advantage_items {
                        order
                        title
                    } 
                    process_title
                    process_description
                    process_items {
                        order
                        title
                        description
                        icon {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    profits_items  {
                        order
                        title
                        description
                    }
                }
            }
        }
        cookies: markdownRemark(
            frontmatter: { lang: { eq: $lang }
            name: { eq: "cookies" }
        }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText
            }
        }
    }`;
