import React from "react";

const Interested = ({pageContext}) => {
    const lang = pageContext.locale.path.default;
    const text = lang === 'es' ? '¿Estás interesado en Dxbot?' : 'Are you interested in Dxbot?';
    const button = lang === 'es' ? 'Contactar con ventas' : 'Contact sales';

    return (
        <section className="pt-10 pt-md-10 pb-8 bg-white border-top">
            <div className="container pb-6 pb-md-8">
                <div className="row align-items-center">
                    <div className="col-12 col-md">
                        <h2 className="font-weight-bold mb-1 spacing">
                            {text}
                        </h2>
                    </div>

                    <div className="col-12 col-md-auto">
                        <a href="#" data-toggle="modal" data-target="#modal-contact-sales" className="btn btn-primary spacing btn-sm">
                            {button}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Interested
